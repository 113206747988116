<template>
  <v-main>
    <v-container v-if="!loading && !!table.uuid">
      <v-row justify="space-around">
        <v-card flat>
          <v-img height="500" src="@/assets/images/flyer_background.jpg">
            <div class="table-title flex flex-column justify-space-between">
              <h1 class="display-4 text-center pt-5">{{ table.number }}</h1>
              <div class="mx-auto">
                <v-img
                  height="128"
                  width="128"
                  src="@/assets/images/qr.png"
                ></v-img>
              </div>
              <p
                class="body-1 font-weight-medium text-center mx-5"
                v-if="$route.query.lang === 'en'"
              >
                Scan this code to view our
                <span class="red--text">menu</span> and place your order faster
              </p>
              <p class="body-1 font-weight-medium text-center mx-5" v-else>
                Escanea el código para ver nuestra
                <span class="red--text">carta</span> y <br />
                haz tu pedido
              </p>
              <p class="text-center">{{ table.pack.alias }}</p>
            </div>
          </v-img>
          <v-card-text>
            <v-simple-table>
              <template v-slot:default>
                <tbody>
                  <tr>
                    <td class="text-h5 py-3">Alias</td>
                    <td class="text-h5 py-3">{{ table.pack.alias }}</td>
                  </tr>
                  <tr>
                    <td class="text-h5 py-3">Número</td>
                    <td class="text-h5 py-3">{{ table.number }}</td>
                  </tr>
                  <tr>
                    <td class="text-h5 py-3">Idioma</td>
                    <td class="text-h5 py-3">
                      {{ $route.query.lang }}
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-card-text>
        </v-card>
      </v-row>
    </v-container>
    <!-- Loading screen -->
    <easy-serve-loading :loading="loading"></easy-serve-loading>
  </v-main>
</template>

<script>
import EasyServeLoading from '@/components/EasyServeLoading';
import { mapGetters } from 'vuex';

export default {
  components: { EasyServeLoading },

  data: () => ({
    table: {},
  }),

  computed: {
    ...mapGetters('security', {
      loading: 'loading',
    }),
  },

  created() {
    // Scan the current table
    const payload = {
      table: this.$route.params.table,
    };

    this.$store
      .dispatch('security/scan', payload)
      .then(response => {
        // If there is no restaurant then we are logged
        // in as admins and this is a table that was not yet assigned
        if (response.data.restaurant_uuid === null) {
          // We must show the the table data
          this.table = response.data.table;
        } else {
          // Now we navigate to the homepage
          this.showRestaurant();
        }
      })
      .catch(error => this.showError(error));
  },

  methods: {
    showRestaurant() {
      this.$router.push({
        name: 'menu',
      });
    },

    showError(error) {
      // Go to some error page ..
      if (error.response.status == 429) {
        return this.$router.push({ name: 'error429' });
      }

      // Go to some error page ..
      if (error.response.status == 404) {
        return this.$router.push({ name: 'error404' });
      }
    },
  },
};
</script>

<style>
.table-title {
  color: white;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center !important;
  margin: 0 6rem;
  min-height: 100% !important;
}
</style>
